<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">确认出库</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" v-permission="['erp:smOutStoreRedeem:sureRedeem']" @click="saveData">保存</div>
        <div class="erp-primary__btn" v-permission="['erp:smOutStoreRedeem:sureRedeem']" @click="submit">确认出库</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :model="initData" :rules="rules">
        <div class="mali-edit__form" style="width: 100%">
          <el-row :gutter="16" type="flex" class="">
            <el-col :span="12">
              <el-form-item label="出库日期">
                <el-date-picker
                  v-model="initData.outstoreDate"
                  type="date"
                  disabled
                  value-format="YYYY-MM-DD"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结算日期">
                <el-date-picker
                  v-model="initData.settleDate"
                  disabled
                  type="date"
                  value-format="YYYY-MM-DD"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="车牌号" prop="plateNumber">
                <el-input
                  :maxlength="8"
                  v-model="initData.plateNumber"
                  clearable
                  @blur="changePlateNumber($event)"
                  placeholder="请输入车牌号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="挂车号" prop="trailerNo">
                <el-input
                  :maxlength="7"
                  v-model="initData.trailerNo"
                  clearable
                  @blur="changeTrailerNumber($event)"
                  placeholder="请输入挂车号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="司机姓名" prop="driverName">
                <el-input
                  v-model="initData.driverName"
                  @blur="changeDriverName($event)"
                  clearable
                  :maxlength="4"
                  placeholder="请输入司机姓名"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="driverIdentityNumber">
                <el-input
                  :maxlength="18"
                  v-model="initData.driverIdentityNumber"
                  @blur="changeDriverCar($event)"
                  clearable
                  placeholder="请输入身份证号"
                  class="mali-full__inputcom"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="司机手机" prop="driverMobile">
                <el-input
                  :maxlength="11"
                  v-model="initData.driverMobile"
                  @blur="changeDriverMobile($event)"
                  clearable
                  placeholder="请输入司机手机"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否抄码" prop="isScan">
                <el-select v-model="initData.isScan" class="mali-full__input" @change="isScanChange" placeholder="请选择是否抄码">
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否控货" prop="isControl">
                <el-select v-model="initData.isControl" class="mali-full__input" @change="isControlChange" placeholder="请选择是否控货">
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input
                  v-model="initData.outstoreIssue"
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  placeholder="请输入备注"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- <el-col :span="24"> -->
      </el-form>
      <div class="product_table_style">
        <el-table
          :show-summary="showSummary"
          :summary-method="summaryFn"
          :data="initData.productDtos"
          border
          class="egrid erp-table__list product_table_total erp-view__tabletap"
        >
          <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" width="190" show-overflow-tooltip>
            <template #default="scope">
              {{ filterName(scope.row.businessContractNo) }}
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="countryName" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              {{ filterName(scope.row.countryName) }}
            </template>
          </el-table-column>
          <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              {{ filterName(scope.row.plantNo) }}
            </template>
          </el-table-column>

          <el-table-column label="中文品名" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              {{ filterName(scope.row.nameCn) }}
            </template>
          </el-table-column>
          <el-table-column label="入库件数" prop="instoreCount" class-name="no-pd" align="center" show-overflow-tooltip width="80">
            <template #default="scope">
              {{ filterName(getThoundand(scope.row.instoreCount)) }}
            </template>
          </el-table-column>
          <el-table-column label="出库件数" prop="expectCount" class-name="no-pd" align="center" show-overflow-tooltip width="80">
            <template #default="scope">
              {{ filterName(getThoundand(scope.row.expectCount)) }}
            </template>
          </el-table-column>
          <el-table-column
            label="实际出库件数"
            prop="actualCount"
            align="center"
            class-name="no-pd required importnice_naddd"
            show-overflow-tooltip
            width="120"
          >
            <template #default="scope">
              <template v-if="scope.row.isshow">
                {{ filterName(scope.row.actualCount) }}
              </template>
              <template v-else>
                <el-input v-model="scope.row.actualCount" placeholder :maxlength="4" class="confirmaadd" @input="countPrice($event, scope.row)"></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="出库净重(kg)" prop="outWeight" class-name="no-pd" align="center" show-overflow-tooltip width="110">
            <template #default="scope">
              {{ filterName(scope.row.outWeightDesc) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-col> -->
      </div>
      <div class="op-16">
        <div>
          <div class="confirmadd__title" style="margin-top: 30px">出库文件</div>
        </div>
        <el-col :span="24">
          <div style="margin-top: 18px">出库单文件</div>
          <div class="border_stock_add">
            <SingleFileContent :fileType="fileType" :fromType="1" @success="getOutBoundUrls" :max="3" :fileList="attachmentDtosArray"> </SingleFileContent>
          </div>
        </el-col>
        <el-col :span="24">
          <div style="margin-top: 14px">码单文件</div>
          <div class="border_stock_add" style="border: 1px solid rgba(220, 223, 230, 1)">
            <SingleFileContent :fileType="1" :fromType="1" @success="getCodeSheettUrls" :max="1" :fileList="attachmentDtoArray"> </SingleFileContent>
          </div>
        </el-col>
      </div>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: {
    SingleFileContent,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      initData: {},
      showSummary: true,
      isAjaxLoading: false,
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt,.xls,.xlsx,.zip,.PDF,.DOC,.DOCX,.JPG,.PNG,.GIF,.TXT,.XLS,.XLSX,.ZIP',
      fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx,zip   大小上限：30M',
      rules: {
        plateNumber: [
          {
            required: true,
            message: '请输入车牌号',
            trigger: 'blur',
          },
        ],
        isScan: [
          {
            required: true,
            message: '请选择是否抄码',
            trigger: 'change',
          },
        ],
        isControl: [
          {
            required: true,
            message: '请选择是否控货',
            trigger: 'change',
          },
        ],
        driverName: [
          {
            required: true,
            message: '请输入司机姓名',
            trigger: 'blur',
          },
        ],
        driverMobile: [
          {
            required: true,
            message: '请输入司机手机',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '手机长度为应为11位',
            trigger: 'blur',
          },
        ],
      },
      attachmentDto: [], // 码单文件
      attachmentDtoArray: [], // 码单文件
      attachmentDtosArray: [], // 出库单文件
      formAttachmentList: [],
      productList: [],
      attachmentType: {
        // 选择类型
        label: null,
        value: null,
      },
      attachmentTypemd: {
        // 选择类型
        label: null,
        value: null,
      },
      objdata: {
        actualCount: null,
        businessContractNo: '合计',
        containerNo: null,
        country: null,
        countryName: null,
        expectCount: null,
        id: null,
        instoreCount: null,
        instoreReceiptNo: null,
        isNeedCtrl: false,
        isNeedScan: false,
        leftCount: null,
        nameCn: null,
        outWeight: null,
        plantNo: null,
        isshow: true,
        sellStmtId: null,
        stockProductId: null,
        unitWeight: null,
      },
      isNewOrder: null,
      businessContractNo: null,
      customerContractNo: null,
    };
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
  created() {
    this.initialize(this.editData.id);
  },
  methods: {
    changePlateNumber(e) {
      let value = e.target.value;
      value = value.replace(/[^\w\u4E00-\u9FA5]/g, ''); // 中文、英文、数字

      this.initData.plateNumber = value;
    },
    changeTrailerNumber(e) {
      let value = e.target.value;
      value = value.replace(/[^\w\u4E00-\u9FA5]/g, ''); // 中文、英文、数字
      this.initData.trailerNo = value;
    },
    changeDriverCar(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符

      this.initData.driverIdentityNumber = value;
    },
    changeDriverName(e) {
      let value = e.target.value;
      value = value.replace(/[^\u4e00-\u9fa5]/g, ''); // 只能输入中文

      this.initData.driverName = value;
    },
    changeDriverMobile(e) {
      let value = e.target.value;
      value = value.replace(/\D/g, ''); // 只能输入中文

      this.initData.driverMobile = value;
    },
    getThoundand(val) {
      if (!isNaN(val) && val !== null) {
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;

        const qStr = String(val).replace(reg, '$1,');
        return qStr;
      } else {
        return '';
      }
    },
    summaryFn(param) {
      // 自定义过显示合计的方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const noshow = [1, 2, 3, 7, 8];
        if (noshow.includes(index)) {
          sums[index] = '';
          return;
        }
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (~String(sums[index]).indexOf('.')) {
            sums[index] = parseFloat(sums[index]).toFixed(2);
          }
          sums[index] = String(sums[index]).replace(reg, '$1,');
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    initialize(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/outStoreDetail/' + id,
        success: (res) => {
          if (res.code === 200) {
            this.initData = res.result;
            const data = JSON.parse(JSON.stringify(res.result.productDetailVos));
            data.forEach((v) => {
              if (v.outWeight) {
                v.outWeightDesc = this._thousandBitSeparator(v.outWeight);
              } else {
                v.outWeightDesc = 0;
              }
              if (!v.actualCount) {
                v.actualCount = '';
              }
            });

            this.initData.productDtos = data;
            this.objdata.instoreCount = this.initData.totalInstoreCount;
            this.objdata.expectCount = this.initData.totalOutstoreCount;
            this.objdata.outWeight = this.initData.totalWeight;
            // 新需求重命名文件
            this.isNewOrder = res.result.isNewOrder;
            this.businessContractNo = res.result.businessContractNo;
            this.customerContractNo = res.result.customerContractNo;
            // 新需求重命名文件
            if (res.result.attachmentVos && res.result.attachmentVos.length > 0) {
              this.attachmentDtosArray = res.result.attachmentVos;
              this.initData.attachmentDtos = res.result.attachmentVos;
            }
            if (res.result.attachment) {
              this.attachmentDtoArray = [res.result.attachment];
              this.initData.attachmentDto = res.result.attachment;
            }

            if (this.initData.productDtos.length > 1) {
              this.showSummary = true;
            } else {
              this.showSummary = false;
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    countPrice(ev, row) {
      row.actualCount = row.actualCount.replace(/[^0-9]/gi, '');
      row.actualCount = row.actualCount.replace(/^0+\./g, '0.');
      row.actualCount = row.actualCount.match(/^0+[1-9]+/) ? row.actualCount.replace(/^0+/g, '') : row.actualCount;
      if (!row.actualCount || row.actualCount === '0' || row.actualCount === '00' || row.actualCount === '000' || row.actualCount === '0000') {
        row.actualCount = 0;
      }
      let total = Number(row.unitWeight) * Number(row.actualCount);
      if (isNaN(total)) {
        total = 0;
      }
      total = total === 0 ? 0 : this._formatFloat(total, 3);
      row.outWeight = total;
      row.outWeightDesc = this._thousandBitSeparator(total);
    },
    getOutBoundUrls(data) {
      let attachmentList = [];
      data.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      //  重命名文件
      if (attachmentList && attachmentList.length > 0) {
        attachmentList = this.formatFileName(attachmentList, '出库单');
        console.log(attachmentList);
        this.attachmentDtosArray = attachmentList;
        this.initData.attachmentDtos = attachmentList;
      } else {
        this.initData.attachmentDtos = [];
      }
    },
    getCodeSheettUrls(data) {
      // 获取到上传的文件码单文件
      let attachment = {};

      if (data && data.length > 0) {
        attachment.name = data[0].name;
        attachment.url = data[0].url;

        attachment = this.formatFileName(attachment, '码单');
        console.log(attachment);
        this.attachmentDtoArray = [attachment];
        this.initData.attachmentDto = attachment && attachment.name ? attachment : null;
      } else {
        this.initData.attachmentDto = null;
      }
    },
    /**
     * 修改文件名
     * @param fileArr 上传组件返回的文件数组[{name, url}]
     * @param type 文件类型: 出库单, 码单
     */
    formatFileName(fileArr, type) {
      const data = JSON.parse(JSON.stringify(fileArr));
      // 1.如果是旧的订单，则#销售合同号尾号(例如721-3)#-#证件类型#
      // 2.如果是新的订单，则#销售合同号客户编号(例如6003-0003)#-#合同编号#-#证件类型#
      // 销售合同号尾号 businessContractNo
      // 销售合同号客户编号 customerContractNo
      let { isNewOrder, businessContractNo, customerContractNo } = this;
      let index;
      if (isNewOrder) {
        // 新订单
        index = this.findChart(customerContractNo, '-', 1);
        businessContractNo = customerContractNo.substring(index + 1);
      } else {
        // 旧订单
        index = this.findChart(businessContractNo, '-', 1);
        businessContractNo = businessContractNo.substring(index + 1);
      }
      if (index > -1) {
        let name = businessContractNo;
        name += '-' + type;
        if (type === '出库单') {
          data.forEach((v, i) => {
            // 获取文件后缀名
            const suffix = v.url.substring(v.url.lastIndexOf('.') + 1);
            v.name = name + 0 + (i + 1) + '.' + suffix;
          });
          return data;
        }
        if (type === '码单') {
          // 获取文件后缀名
          const suffix = data.url.substring(data.url.lastIndexOf('.') + 1);
          data.name = name + '.' + suffix;
          return data;
        }
      } else {
        // 如果销售合同号不符合规则就直接返回
        return data;
      }
    },
    /**
     * 获取用-分隔的String的-内容
     * @param str 内容
     * @param cha 分隔符
     * @param num 第几个分隔符
     */
    findChart(str, cha) {
      const strIndexArr = [];
      for (let i = 0; i < str.length; i++) {
        if (cha === str[i]) {
          strIndexArr.push(i);
        }
      }
      const len = strIndexArr.length;
      if (len >= 2) {
        return strIndexArr[len - 2];
      } else {
        return -1;
      }
    },

    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const obj = JSON.parse(JSON.stringify(this.initData));
          for (let index = 0; index < obj.productDtos.length; index++) {
            if (obj.productDtos[index].businessContractNo === '合计') {
              obj.productDtos.splice(index, 1);
              continue;
            }
            if (!obj.productDtos[index].actualCount && obj.productDtos[index].actualCount !== 0) {
              this.errorTip('请填写实际出库件数');
              return;
            }
          }
          this.saveSubmitForm(obj);
        }
      });
    },
    // 保存操作，非确认出库操作
    saveData() {
      // todoList
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/outStoreRedeem/saveOutStore',
        data: this.initData,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功', 3000);
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    saveSubmitForm(value) {
      // 保存或编辑表单
      if (!this.isAjaxLoading) {
        this.isAjaxLoading = true;
        this.ajax({
          type: 'POST',
          url: '/malicrm/sm/outStoreRedeem/sureOutStore',
          data: value,
          success: (res) => {
            if (res.code === 200) {
              this.isAjaxLoading = false;
              this.successTip('确认出库成功', 3000);
              this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            } else {
              this.isAjaxLoading = false;
              this.errorTip(res.message);
            }
          },
        });
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    // 是否抄码change事件
    isScanChange(e) {
      if (e) {
        this.initData.isScanDesc = '是';
      } else {
        this.initData.isScanDesc = '否';
      }
    },
    // 是否控货change事件
    isControlChange(e) {
      if (e) {
        this.initData.isControlDesc = '是';
      } else {
        this.initData.isControlDesc = '否';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.border_stock_add {
  width: 100%;
  min-height: 57px;
  border: 1px solid rgba(220, 223, 230, 1);
  margin: 17px 0;
  padding: 16px;
}
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
</style>
