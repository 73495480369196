<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看确认出库申请</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" v-permission="['erp:smStock:operateOutStore']" @click="cancelOutStore">取消申请</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <el-row :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="出库日期" :value="initData.outstoreDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="结算日期" :value="initData.settleDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="车 牌 号" :value="initData.plateNumber"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="挂 车 号" :value="initData.trailerNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="司机姓名" :value="initData.driverName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="身份证号" :value="initData.driverIdentityNumber"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="司机手机" :value="initData.driverMobile"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="是否抄码" :value="initData.isScanDesc"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="是否控货" :value="initData.isControlDesc"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="备注" :value="initData.outstoreIssue"></erp-view>
        </el-col>
      </el-row>
      <el-col :span="24" style="margin-top: 30px">
        <el-table :data="initData.productDetailVos" border class="egrid erp-table__list erp-view__table">
          <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" show-overflow-tooltip width="140">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.businessContractNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="countryName" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.countryName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.plantNo) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="中文品名" class-name="no-pd" align="center" width="160" show-overflow-tooltip>
            <template #default="scope">
              <div>
                {{ filterName(scope.row.nameCn) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="出库件数" class-name="no-pd" align="center" show-overflow-tooltip width="80">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="出库净重(kg)" class-name="no-pd" align="center" show-overflow-tooltip width="110">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.expectWeight)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="入库单号" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.instoreReceiptNo) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="柜号" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.containerNo) }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      initData: {},
      tatoldata: {
        actualCount: '',
        businessContractNo: '合计',
        containerNo: '',
        country: null,
        countryName: null,
        expectCount: null,
        id: null,
        instoreCount: null,
        instoreReceiptNo: null,
        isNeedCtrl: false,
        isNeedScan: false,
        leftCount: null,
        nameCn: null,
        outWeight: null,
        plantNo: null,
        sellStmtId: null,
        stockProductId: null,
        unitWeight: null,
      },
      needScanOption: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      needCtrlOption: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    };
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
  created() {
    this.initialize(this.editData.id);
  },
  methods: {
    initialize(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/outStoreDetail/' + id,
        success: (res) => {
          if (res.code === 200) {
            this.initData = res.result;
            if (this.initData.productDetailVos.length > 1) {
              this.initData.productDetailVos = this.initData.productDetailVos.filter((item) => {
                return item.expectCount;
              });
              this.tatoldata.expectWeight = this.initData.totalActualWeight;
              this.tatoldata.expectCount = this.initData.totalOutstoreCount;
              this.initData.productDetailVos.push(this.tatoldata);
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 取消出库
    cancelOutStore() {
      const { id, version } = this.initData;
      this.$erpConfirm('确定取消申请吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: 'malicrm/sm/outStoreRedeem/cancelApplyOutstore',
            data: { id, version },
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                this.options = res.result;
                this.successTip('已取消出库', 3000);
                this.$emit('success', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
</style>
