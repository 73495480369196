
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import confirmoutstock from './confirmoutstock.vue';
import confirmredemption from './confirmredemption.vue';
import confirmoutdetail from './confirmoutdetail.vue';
import redemptiondetail from './redemptiondetail.vue';
import bookingoutform from './../inventory/bookingoutform.vue';
import outstockdetail from './outstockdetail.vue';
import redemptioncompletedetail from './redemptioncompletedetail.vue';
import bookingredemptionform from './../inventory/bookingredemptionform.vue';
import confirmApplyOut from './confirmApplyOut.vue'; // 确认出库申请
import confirmApplyOutDetail from './confirmApplyOutDetail.vue'; // 查看确认出库申请详情
import confirmApplyRedemption from './confirmApplyRedemption.vue'; // 确认赎货申请
import confirmApplyRedemptionDetail from './confirmApplyRedemptionDetail.vue'; // 查看确认赎货申请
import updateAttach from './updateAttach.vue'; // 更新附件
import { erpConfirm, errorMessage, exportFile, formatTime } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';

export default defineComponent({
  name: 'deliveryRecordList',
  components: {
    ErpList,
    confirmoutstock,
    confirmredemption,
    confirmoutdetail,
    redemptiondetail,
    bookingoutform,
    outstockdetail,
    redemptioncompletedetail,
    bookingredemptionform,
    confirmApplyOut, // 确认申请,
    confirmApplyOutDetail, // 查看确认出库申请详情
    confirmApplyRedemption, // 确认赎货申请
    confirmApplyRedemptionDetail, // 查看确认赎货申请
    updateAttach, // 更新附件
  },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const data = reactive({
      formType: 1,
      productData: [],
      type: 1,
      editData: {},
      rules: {
        timerange: [
          {
            required: true,
            message: '请选择开始时间和结束时间',
            trigger: 'blur',
          },
        ],
      },
    });
    const formRef = ref();
    const exportForm = reactive({
      createdTimeBegin: null,
      createdTimeEnd: null,
      timerange: null,
    });
    const exportDialog = reactive({
      title: '',
      visible: false,
    });

    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };

    // 导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/sm/outStoreRedeem/exportOutStoreRedeem',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    const successCallback = (status) => {
      if (data.formType === 4) {
        data.formType = 2;
        return;
      }
      closeVisible();
      if (status) {
        refreshTable(status);
      }
    };
    const cancelFn = (status) => {
      if (status) {
        closeVisible();
        refreshTable(true);
      } else {
        closeVisible();
      }
    };
    // 预约送货
    const confirmOut = (row) => {
      data.editData = row;
      data.formType = 1;
      showVisible();
    };
    // 确认申请
    const confirmApply = (row, type) => {
      data.editData = row;
      if (type === '出库') {
        data.formType = 11;
      }
      if (type === '查看确认出库申请') {
        data.formType = 12;
      }
      if (type === '赎货') {
        // 打开 确认赎货申请弹窗
        data.formType = 21;
      }
      if (type === '查看确认赎货申请') {
        // 打开 查看确认赎货申请弹窗
        data.formType = 24;
      }
      showVisible();
    };
    // 审核
    const confirmRedemption = (row, operaType = 2) => {
      data.editData = row;
      data.formType = operaType;
      showVisible();
    };
    // 更新附件
    const updateAttach = (row, type) => {
      data.editData = row;
      if (type === '更新附件') {
        data.formType = 9;
      }
      showVisible();
    };

    const redemptioedit = () => {
      data.formType = 23;
    };
    const outdetailedit = () => {
      data.formType = 5;
    };
    // 查看详情
    const rowClick = (row, column) => {
      if (column.label !== '操作') {
        data.editData = row;
        // 查看出库
        if (Number(row.apptStatus) === 2 && Number(row.apptType) === 1) {
          data.formType = 7;
          showVisible();
          return '';
        }
        // 查看出库（已取消）
        if (Number(row.apptStatus) === 3 && Number(row.apptType) === 1) {
          data.formType = 7;
          showVisible();
          return '';
        }
        // 查看赎货（已完成）
        if (Number(row.apptStatus) === 2 && Number(row.apptType) === 2) {
          data.formType = 8;
          showVisible();
          return '';
        }
        // 查看赎货(取消)
        if (Number(row.apptStatus) === 3 && Number(row.apptType) === 2) {
          confirmRedemption(row, 8);
          return '';
        }
        // 查看赎货(预约)
        if (Number(row.apptStatus) === 1 && Number(row.apptType) === 2) {
          confirmRedemption(row, 22);
          return '';
        }
        // 预约出库查看
        if (Number(row.apptStatus) === 1) {
          data.formType = 3;
          showVisible();
          return '';
        }

        // 查看确认出库申请
        if (Number(row.apptStatus) === 4 && Number(row.apptType) === 1) {
          confirmApply(row, '查看确认出库申请');
        }
        // 查看确认赎货申请
        if (Number(row.apptStatus) === 4 && Number(row.apptType) === 2) {
          confirmApply(row, '查看确认赎货申请');
        }
      }
    };
    const exportOrderDetail = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // 导出操作
          if (timeStatus.value === true) {
            const params = Object.assign({}, tableRef.value.getQueryParams());
            params.createdTimeBegin = exportForm.createdTimeBegin;
            params.createdTimeEnd = exportForm.createdTimeEnd;
            startTimer();
            exportFile({
              type: 'POST',
              url: '/malicrm/sm/outStoreRedeem/exportOutStoreRedeemDetail',
              data: params,
            });
            exportDialog.visible = false;
          } else {
            errorMessage('导出送货明细的点击间隔请勿小于30秒');
          }
        }
      });
    };
    const openDetailDialog = () => {
      exportDialog.visible = true;
      exportForm.createdTimeBegin = null;
      exportForm.createdTimeEnd = null;
      exportForm.timerange = null;
    };
    const getDate = (e) => {
      if (e) {
        exportForm.createdTimeBegin = formatTime(e[0], 'YYYY-MM-DD') as any;
        exportForm.createdTimeEnd = formatTime(e[1], 'YYYY-MM-DD') as any;
      }
    };
    return {
      redemptioedit,
      outdetailedit,
      exportOrderDetail,
      getDate,
      formRef,
      openDetailDialog,
      exportForm,
      cancelFn,
      successCallback,
      confirmOut,
      confirmApply,
      confirmRedemption,
      updateAttach,

      exportDialog,
      ...toRefs(data),
      exportFileFn,
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
    };
  },
});
