<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看确认赎货申请</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" v-permission="['erp:smStock:operateOutStore']" @click="cancelRedemption">取消赎货</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <el-row :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="结算日期" :value="formData.settleDate"></erp-view>
        </el-col>
      </el-row>
      <el-col :span="24" style="margin-top: 30px">
        <el-table :data="formData.productDetailVos" border class="egrid erp-table__list erp-view__table">
          <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" show-overflow-tooltip width="176">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.businessContractNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="countryName" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.countryName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.plantNo) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="中文品名" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>
                {{ filterName(scope.row.nameCn) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="赎货件数" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="赎货净重(kg)" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(_formatFloat(scope.row.redeemWeight, 3))) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      writtenOffList: [
        {
          value: 0,
          label: '直送',
        },
        {
          value: 1,
          label: '入库',
        },
      ],
      formData: {
        // 绑定表单数据
        autoCertNo: '',
        hsCode: null,
        id: null,
        isWrittenOff: 1,
        orderId: null,
        unitPrice: null,
        validDate: null,
        version: null,
        weight: null,
      },
      formAttachmentList: [], // 上传保存用上传列表
      productList: [],
      objdata: {
        actualAmount: '',
        actualAmountDisplay: null,
        actualWeight: 0,
        actualWeightDisplay: null,
        category: null,
        contractAmount: 0,
        contractAmountDisplay: null,
        contractWeight: 0,
        contractWeightDisplay: null,
        country: null,
        elecInfoStatus: null,
        elecInfoStatusDisplay: null,
        healthCertNo: null,
        labelId: 0,
        nameCn: null,
        nameEn: null,
        packageCount: 0,
        packageCountDisplay: null,
        plantNo: null,
        tradeCurrency: null,
        tradeCurrencyDisplay: null,
        unitPrice: 0,
        unitPriceDisplay: null,
        isshow: true,
        varietyName: null,
      },
      Totaldata: {
        businessContractNo: '合计',
        country: null,
        countryName: null,
        expectCount: null,
        id: null,
        instoreCount: null,
        nameCn: null,
        plantNo: null,
        redeemValue: null,
        redeemWeight: null,
        sellStmtId: null,
        stockProductId: null,
        unitPrice: null,
        unitWeight: null,
      },
      rules: {},
    };
  },
  created() {
    this.getinit();
  },
  methods: {
    getinit() {
      this.ajax({
        type: 'GET',
        url: 'malicrm/sm/outStoreRedeem/redeemDetail/' + this.editData.id,
        data: null,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            if (this.formData.productDetailVos.length > 1) {
              this.formData.productDetailVos = this.formData.productDetailVos.filter((item) => {
                return item.expectCount;
              });
              // this.Totaldata.instoreCount = this.formData.totalInstoreCount;
              this.Totaldata.redeemWeight = this.formData.totalWeight;
              this.Totaldata.expectCount = this.formData.totalRedeemCount;
              this.Totaldata.redeemValue = this.formData.totalValue;
              this.formData.productDetailVos.push(this.Totaldata);
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },

    // 编辑
    edit() {
      this.$emit('edit');
    },
    changeAutoCertNo(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符
      this.formData.autoCertNo = value;
    },
    /**
     * 检查小数位数
     * @param e 时间
     * @param name 属性名
     * @param len 最大整数位数
     * @param dot 截取位数
     */
    changeDecimal(e, name, len, dot) {
      let value = e.target.value;
      // 格式化汇率格式
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= len) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, dot);
        }
      }
      // 只能输入两个小数
      this.formData[name] = value;
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    // 取消赎货
    cancelRedemption() {
      const { id, version } = this.formData;
      this.$erpConfirm('确定取消赎货吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: 'malicrm/sm/outStoreRedeem/cancelApplyOutstore',
            data: { id, version },
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                this.options = res.result;
                this.successTip('已取消赎货', 3000);
                this.$emit('success', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
</style>
