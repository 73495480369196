<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看预约赎货1</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" v-if="hasPermission('erp:smStock:operateRedeem')" @click="edit">编辑</div>
        <div class="erp-primary__btn" v-if="hasPermission('erp:smStock:operateRedeem')" @click="cancelRedemption">取消赎货</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <el-row :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="结算日期" :value="initData.settleDate"></erp-view>
        </el-col>
      </el-row>
      <el-col :span="24" style="margin-top: 30px">
        <el-table :data="initData.productDetailVos" border class="egrid erp-table__list erp-view__table">
          <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" show-overflow-tooltip width="176">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.businessContractNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="countryName" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.countryName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="厂号" prop="contractAmountDisplay" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.plantNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="中文品名" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>
                {{ filterName(scope.row.nameCn) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="赎货件数" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="赎货净重(kg)" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.redeemWeight)) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      initData: {},
      tatoldata: {
        actualCount: null,
        businessContractNo: '合计',
        containerNo: null,
        country: null,
        countryName: null,
        expectCount: null,
        id: null,
        instoreCount: null,
        instoreReceiptNo: null,
        isNeedCtrl: false,
        isNeedScan: false,
        leftCount: null,
        nameCn: null,
        outWeight: null,
        plantNo: null,
        sellStmtId: null,
        stockProductId: null,
        unitWeight: null,
      },
    };
  },

  created() {
    this.initialize(this.editData.id);
  },
  methods: {
    initialize(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/redeemDetail/' + id,
        success: (res) => {
          if (res.code === 200) {
            this.initData = res.result;
            if (this.initData.productDetailVos.length > 1) {
              this.tatoldata.outWeight = this.productList.totalWeight;
              this.tatoldata.instoreCount = this.productList.totalInstoreCount;
              this.tatoldata.expectCount = this.productList.totalOutstoreCount;
              this.productList.productDetailVos.push(this.tatoldata);
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 取消赎货
    cancelRedemption() {
      this.$emit('cancelRedemption');
    },
    // 编辑
    edit() {
      this.$emit('edit');
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
</style>
