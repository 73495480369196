<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title" v-if="formType == 2">确认赎货</div>
      <div class="erp-form__title" v-if="formType == 22">查看预约赎货</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" v-if="formType == 2" v-permission="['erp:smOutStoreRedeem:sureRedeem']" @click="submit">确认赎货</div>
        <div class="erp-primary__btn" v-if="formType == 22" v-permission="['erp:smStock:operateRedeem']" @click="btnEdit">编辑</div>
        <div class="erp-primary__btn" v-if="formType == 22" v-permission="['erp:smStock:operateRedeem']" @click="btnCancel">取消赎货</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top">
        <div class="mali-edit__form" style="width: 100%">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="结算日期">
                <el-date-picker
                  v-model="initData.settleDate"
                  disabled
                  type="date"
                  value-format="YYYY-MM-DD"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-col :span="24">
          <el-table
            :data="initData.productDetailVos"
            border
            :show-summary="showSummary"
            :summary-method="summaryFn"
            class="egrid confirm-redemption erp-table__list erp-view__table"
          >
            <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" show-overflow-tooltip width="176">
              <template #default="scope">
                {{ filterName(scope.row.businessContractNo) }}
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="countryName" align="center" class-name="no-pd" show-overflow-tooltip width="100">
              <template #default="scope">
                {{ filterName(scope.row.countryName) }}
              </template>
            </el-table-column>
            <el-table-column label="厂号" class-name="no-pd" align="center" show-overflow-tooltip width="110">
              <template #default="scope">
                {{ filterName(scope.row.plantNo) }}
              </template>
            </el-table-column>

            <el-table-column label="中文品名" class-name="no-pd" align="center" show-overflow-tooltip>
              <template #default="scope">
                {{ filterName(scope.row.nameCn) }}
              </template>
            </el-table-column>
            <el-table-column label="入库件数" prop="instoreCount" class-name="no-pd" align="center" show-overflow-tooltip width="100" v-if="formType == 2">
              <template #default="scope">
                {{ filterName(_thousandBitSeparator(scope.row.instoreCount)) }}
              </template>
            </el-table-column>
            <el-table-column label="赎货件数" prop="expectCount" class-name="no-pd" align="center" show-overflow-tooltip width="100">
              <template #default="scope">
                {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
              </template>
            </el-table-column>
            <el-table-column label="赎货净重(kg)" prop="redeemWeight" class-name="no-pd" align="center" width="130" show-overflow-tooltip>
              <template #default="scope">
                {{ filterName(_thousandBitSeparator(scope.row.redeemWeight)) }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-form>
    </div>
  </div>
</template>
<script>
import { ajaxLoading } from '@/utils/publicMethods';
export default {
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      initData: {
        id: 0,
        productDetailVos: [
          {
            actualCount: null,
            actualValue: null,
            actualWeight: null,
            businessContractNo: null,
            country: null,
            countryName: null,
            expectCount: null,
            id: null,
            instoreCount: null,
            leftCount: null,
            nameCn: null,
            plantNo: null,
            redeemValue: null,
            redeemWeight: null,
            sellStmtId: null,
            stockProductId: null,
            unitPrice: null,
            unitWeight: null,
          },
        ],
        settleDate: null,
        totalActualRedeemCount: null,
        totalActualValue: null,
        totalActualWeight: null,
        totalInstoreCount: null,
        totalRedeemCount: null,
        totalValue: null,
        totalWeight: null,
        version: null,
      },
      showSummary: true,
    };
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
  created() {
    this.initialize(this.editData.id);
  },
  methods: {
    summaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', ''];
      const data = this.initData.productDetailVos;
      const instoreCount = data.reduce((total, item) => {
        const instoreCount = Number(item.instoreCount);
        return total + instoreCount;
      }, 0);

      let expectCount = data.reduce((total, item) => {
        const leftCount = Number(item.expectCount);
        return total + leftCount;
      }, 0);

      const redeemWeight = data.reduce((total, item) => {
        const expectCountTotal = Number(item.redeemWeight);
        return total + expectCountTotal;
      }, 0);
      if (isNaN(expectCount)) {
        expectCount = 0;
      }
      let actualCount = data.reduce((total, item) => {
        const expectCountTotal = Number(item.actualCount);
        return total + expectCountTotal;
      }, 0);
      if (isNaN(actualCount)) {
        actualCount = 0;
      }
      if (this.formType === 2) {
        summaryColumns[4] = this._thousandBitSeparator(instoreCount);
        summaryColumns[5] = this._thousandBitSeparator(expectCount);
        summaryColumns[6] = this._thousandBitSeparator(this._formatFloat(redeemWeight, 3));
      } else {
        summaryColumns[4] = this._thousandBitSeparator(expectCount);
        summaryColumns[5] = this._thousandBitSeparator(this._formatFloat(redeemWeight, 3));
      }

      return summaryColumns;
    },
    initialize(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/redeemDetail/' + id,
        success: (res) => {
          if (res.code === 200) {
            this.initData = res.result;
            this.initData.productDetailVos.forEach((item) => {
              item.redeemWeight = this._formatFloat(item.redeemWeight, 3);
            });
            if (this.initData.productDetailVos.length > 1) {
              this.showSummary = true;
            } else {
              this.showSummary = false;
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     * 提交事件
     */
    submit() {
      // 新增重复提交锁定
      ajaxLoading.lock();
      this.ajax({
        type: 'POST',
        url: 'malicrm/sm/outStoreRedeem/sureRedeem',
        data: { id: this.initData.id, version: this.initData.version },
        loading: true,
        success: (res) => {
          ajaxLoading.unLock();
          if (res.code === 200) {
            this.successTip('已确认赎货', 3000);
            this.$emit('success', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    btnEdit() {
      this.ajax({
        type: 'GET',
        url: 'malicrm/sm/outStoreRedeem/checkOutRedeem/' + this.initData.id,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result === true) {
              const newData = JSON.parse(JSON.stringify(this.initData));
              this.$emit('edit', newData);
            } else {
              this.errorTip('结算单已关联收款，无法编辑');
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     * 取消赎货
     */
    btnCancel() {
      this.$erpConfirm('确定取消赎货吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: 'malicrm/sm/outStoreRedeem/cancelRedeem',
            data: { id: this.initData.id, version: this.initData.version },
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('已取消赎货', 3000);
                this.$emit('success', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-upload__btns {
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;
  }
}
</style>
