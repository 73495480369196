<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="edit" v-if="operateType === 1 && editData.apptStatus !== 3">编辑</div>
        <div class="erp-primary__btn" @click="save" v-if="operateType === 2">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <!-- 查看出库 -->
    <template v-if="operateType === 1">
      <div class="order-base__warp" style="padding-bottom: 40px">
        <el-row :gutter="30" type="flex" class="flex-warp op-16">
          <el-col :span="12">
            <erp-view label="出库日期" :value="productList.outstoreDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="结算日期" :value="productList.settleDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="车 牌 号" :value="productList.plateNumber"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="挂 车 号" :value="productList.trailerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="司机姓名" :value="productList.driverName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="身份证号" :value="productList.driverIdentityNumber"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="司机手机" :value="productList.driverMobile"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="是否抄码" :value="productList.isScanDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="是否控货" :value="productList.isControlDesc"></erp-view>
          </el-col>
          <!-- v-if="editData.apptStatus !== 3" -->
          <el-col :span="24">
            <erp-view label="备注" :value="productList.outstoreIssue"></erp-view>
          </el-col>
        </el-row>
        <el-col :span="24" style="margin-top: 30px">
          <el-table :data="productList.productDetailVos" border class="egrid erp-table__list erp-view__table">
            <el-table-column label="销售合同号" prop="businessContractNo" align="center" class-name="no-pd" show-overflow-tooltip width="180">
              <template #default="scope">
                <div>{{ filterName(scope.row.businessContractNo) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="countryName" class-name="no-pd" align="center" show-overflow-tooltip width="140">
              <template #default="scope">
                <div>{{ filterName(scope.row.countryName) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="140">
              <template #default="scope">
                <div>{{ filterName(scope.row.plantNo) }}</div>
              </template>
            </el-table-column>

            <el-table-column label="中文品名" class-name="no-pd" align="center" show-overflow-tooltip width="160">
              <template #default="scope">
                <div>{{ filterName(scope.row.nameCn) }}</div>
              </template>
            </el-table-column>

            <el-table-column label="出库件数" prop="actualAmount" align="center" class-name="no-pd" show-overflow-tooltip width="120">
              <template #default="scope">
                <div>
                  {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="实际出库件数"
              prop="actualAmount"
              align="center"
              class-name="no-pd"
              show-overflow-tooltip
              width="120"
              v-if="editData.apptStatus !== 3"
            >
              <template #default="scope">
                <div>
                  {{ filterName(_thousandBitSeparator(scope.row.actualCount)) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="出库净重(kg)" prop="outWeight" align="center" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                <div>
                  {{ filterName(_thousandBitSeparator(scope.row.outWeight)) }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>

        <el-row :gutter="30" type="flex" class="flex-warp op-16" v-if="editData.apptStatus !== 3">
          <el-col :span="24">
            <div class="confirmadd__title" style="margin-top: 30px; margin-bottom: 6px">出库文件</div>
          </el-col>
          <el-col :span="12">
            <erp-view label="出库单文件">
              <div class="card-preview_div" v-if="productList.attachmentVos && productList.attachmentVos.length">
                <div
                  class="card-preview"
                  style="width: 100%"
                  :class="index ? 'outStock_madd' : ''"
                  v-for="(item, index) in productList.attachmentVos"
                  :key="index"
                >
                  <div class="card_name_type">{{ item.name }}</div>
                  <img src="../../../../assets/images/preview__zoom.png" alt @click="$showFiles(item.name, item.url)" />
                  <img src="../../../../assets/images/preview_download.png" alt @click="$showFiles(item.name, item.url, false)" />
                </div>
              </div>
              <div v-else>-</div>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="码单文件">
              <div class="card-preview_div">
                <div style="align-items: start">
                  <div class="card-preview" v-if="productList.attachment.name">
                    <div class="card_name_type">
                      {{ productList.attachment.name }}
                    </div>
                    <img
                      src="../../../../assets/images/preview__zoom.png"
                      v-if="productList.attachment.name"
                      alt
                      @click="$showFiles(productList.attachment.name, productList.attachment.url)"
                    />
                    <img
                      src="../../../../assets/images/preview_download.png"
                      v-if="productList.attachment.name"
                      alt
                      @click="$showFiles(productList.attachment.name, productList.attachment.url, false)"
                    />
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </erp-view>
          </el-col>
        </el-row>
      </div>
    </template>
    <!-- 编辑出库 -->
    <template v-else>
      <div class="erp-form__body">
        <el-form ref="form" label-position="top" :show-message="true" :model="productList" :rules="rules">
          <div class="mali-edit__form" style="width: 100%">
            <el-row :gutter="16" type="flex" class="">
              <el-col :span="12">
                <el-form-item label="出库日期" prop="outstoreDate">
                  <el-date-picker v-model="productList.outstoreDate" value-format="YYYY-MM-DD" type="date" prefix-icon=" " style="width: 100%"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结算日期">
                  <el-date-picker v-model="productList.settleDate" disabled value-format="YYYY-MM-DD" prefix-icon=" " style="width: 100%"></el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="车牌号" prop="plateNumber">
                  <el-input
                    :disabled="readonly"
                    :maxlength="8"
                    v-model="productList.plateNumber"
                    clearable
                    @blur="changePlateNumber($event)"
                    placeholder="请输入车牌号"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="挂车号" prop="Trailer">
                  <el-input
                    :disabled="readonly"
                    :maxlength="7"
                    v-model="productList.Trailer"
                    clearable
                    @blur="changeTrailerNumber($event)"
                    placeholder="请输入挂车号"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="司机姓名" prop="driverName">
                  <el-input
                    :disabled="readonly"
                    v-model="productList.driverName"
                    @blur="changeDriverName($event)"
                    clearable
                    :maxlength="4"
                    placeholder="请输入司机姓名"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号" prop="driverIdentityNumber">
                  <el-input
                    :disabled="readonly"
                    :maxlength="18"
                    v-model="productList.driverIdentityNumber"
                    @blur="changeDriverCar($event)"
                    clearable
                    placeholder="请输入身份证号"
                    class="mali-full__inputcom"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="司机手机" prop="driverMobile">
                  <el-input
                    :disabled="readonly"
                    :maxlength="11"
                    v-model="productList.driverMobile"
                    @blur="changeDriverMobile($event)"
                    clearable
                    placeholder="请输入司机手机"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否抄码" prop="isScan">
                  <el-select v-model="productList.isScan" class="mali-full__input" @change="isScanChange" placeholder="请选择是否抄码" :disabled="readonly">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否控货" prop="isControl">
                  <el-select
                    v-model="productList.isControl"
                    class="mali-full__input"
                    @change="isControlChange"
                    placeholder="请选择是否控货"
                    :disabled="readonly"
                  >
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input
                    v-model="productList.outstoreIssue"
                    :maxlength="100"
                    class="mali-full__input11"
                    type="textarea"
                    placeholder="请输入备注"
                    :autosize="{ minRows: 3 }"
                    resize="none"
                    :disabled="readonly"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- <el-col :span="24"> -->
        </el-form>
        <div class="product_table_style">
          <el-col :span="24">
            <el-table :data="productList.productDetailVos" border class="egrid erp-table__list erp-view__table">
              <el-table-column label="销售合同号" prop="businessContractNo" align="center" class-name="no-pd" show-overflow-tooltip width="180">
                <template #default="scope">
                  <div>{{ filterName(scope.row.businessContractNo) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="国家" prop="countryName" class-name="no-pd" align="center" show-overflow-tooltip width="140">
                <template #default="scope">
                  <div>{{ filterName(scope.row.countryName) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="140">
                <template #default="scope">
                  <div>{{ filterName(scope.row.plantNo) }}</div>
                </template>
              </el-table-column>

              <el-table-column label="中文品名" class-name="no-pd" align="center" show-overflow-tooltip width="160">
                <template #default="scope">
                  <div>{{ filterName(scope.row.nameCn) }}</div>
                </template>
              </el-table-column>

              <el-table-column label="出库件数" prop="actualAmount" align="center" class-name="no-pd" show-overflow-tooltip width="120">
                <template #default="scope">
                  <div>
                    {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="实际出库件数"
                prop="actualAmount"
                align="center"
                class-name="no-pd"
                show-overflow-tooltip
                width="120"
                v-if="editData.apptStatus !== 3"
              >
                <template #default="scope">
                  <div>
                    {{ filterName(_thousandBitSeparator(scope.row.actualCount)) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="出库净重(kg)" prop="outWeight" align="center" class-name="no-pd" show-overflow-tooltip>
                <template #default="scope">
                  <div>
                    {{ filterName(_thousandBitSeparator(scope.row.outWeight)) }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <!-- </el-col> -->
        </div>
        <el-row :gutter="30" type="flex" class="flex-warp op-16" v-if="editData.apptStatus !== 3">
          <el-col :span="24">
            <div class="confirmadd__title" style="margin-top: 30px; margin-bottom: 6px">出库文件</div>
          </el-col>
          <el-col :span="12">
            <erp-view label="出库单文件">
              <div class="card-preview_div" v-if="productList.attachmentVos && productList.attachmentVos.length">
                <div
                  class="card-preview"
                  style="width: 100%"
                  :class="index ? 'outStock_madd' : ''"
                  v-for="(item, index) in productList.attachmentVos"
                  :key="index"
                >
                  <div class="card_name_type">{{ item.name }}</div>
                  <img src="../../../../assets/images/preview__zoom.png" alt @click="$showFiles(item.name, item.url)" />
                  <img src="../../../../assets/images/preview_download.png" alt @click="$showFiles(item.name, item.url, false)" />
                </div>
              </div>
              <div v-else>-</div>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="码单文件">
              <div class="card-preview_div">
                <div style="align-items: start">
                  <div class="card-preview" v-if="productList.attachment.name">
                    <div class="card_name_type">
                      {{ productList.attachment.name }}
                    </div>
                    <img
                      src="../../../../assets/images/preview__zoom.png"
                      v-if="productList.attachment.name"
                      alt
                      @click="$showFiles(productList.attachment.name, productList.attachment.url)"
                    />
                    <img
                      src="../../../../assets/images/preview_download.png"
                      v-if="productList.attachment.name"
                      alt
                      @click="$showFiles(productList.attachment.name, productList.attachment.url, false)"
                    />
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </erp-view>
          </el-col>
        </el-row>
      </div>
    </template>
  </div>
</template>
<script>
import { errorTip, successTip } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      operateType: 1, //1.查看    2  编辑
      formData: {
        autoCertNo: '',
      },
      productList: {
        isControl: null,
        isControlDesc: null,
        isScan: null,
        isScanDesc: null,
        attachment: {
          id: null,
          name: null,
          url: null,
        },
        attachmentVos: [
          {
            id: null,
            name: null,
            url: null,
          },
        ],
        driverIdentityNumber: null,
        driverMobile: null,
        driverName: null,
        id: null,
        outstoreDate: null,
        outstoreIssue: null,
        plateNumber: null,
        productDetailVos: [
          {
            actualCount: null,
            businessContractNo: null,
            containerNo: null,
            country: null,
            countryName: null,
            expectCount: null,
            id: null,
            instoreCount: null,
            instoreReceiptNo: null,
            isNeedCtrl: false,
            isNeedScan: false,
            leftCount: null,
            nameCn: null,
            outWeight: null,
            plantNo: null,
            sellStmtId: null,
            stockProductId: null,
            unitWeight: null,
          },
        ],
        settleDate: null,
        totalActualCount: null,
        totalInstoreCount: null,
        totalLeftCount: null,
        totalOutstoreCount: null,
        totalWeight: null,
        version: null,
      },
      objdata: {
        actualAmount: '',
        actualAmountDisplay: null,
        actualWeight: 0,
        actualWeightDisplay: null,
        category: null,
        contractAmount: 0,
        contractAmountDisplay: null,
        contractWeight: 0,
        contractWeightDisplay: null,
        country: null,
        elecInfoStatus: null,
        elecInfoStatusDisplay: null,
        healthCertNo: null,
        labelId: 0,
        nameCn: null,
        nameEn: null,
        packageCount: 0,
        packageCountDisplay: null,
        plantNo: null,
        tradeCurrency: null,
        tradeCurrencyDisplay: null,
        unitPrice: 0,
        unitPriceDisplay: null,
        isshow: true,
        varietyName: null,
      },
      tatoldata: {
        actualCount: null,
        businessContractNo: '合计',
        containerNo: null,
        country: null,
        countryName: null,
        expectCount: null,
        id: null,
        instoreCount: null,
        instoreReceiptNo: null,
        isNeedCtrl: false,
        isNeedScan: false,
        leftCount: null,
        nameCn: null,
        outWeight: null,
        plantNo: null,
        sellStmtId: null,
        stockProductId: null,
        unitWeight: null,
      },
      rules: {
        outstoreDate: [
          {
            required: true,
            message: '请选择出库日期',
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.operateType === 1 ? '查看出库' : '编辑出库';
    },
    readonly() {
      return this.operateType === 2;
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
  created() {
    this.getinit();
  },
  methods: {
    getinit() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/outStoreDetail/' + this.editData.id,
        success: (res) => {
          if (res.code === 200) {
            this.productList = res.result;
            if (!this.productList.attachment) {
              this.productList.attachment = {};
            }
            this.productList.productDetailVos.forEach((item) => {
              if (!item.outWeight) {
                item.outWeight = 0;
              }
            });
            this.productList.productDetailVos = this.productList.productDetailVos.filter((item) => {
              return item.expectCount;
            });
            if (this.productList.productDetailVos.length > 1) {
              this.tatoldata.outWeight = this._thousandBitSeparator(this.productList.totalWeight);
              this.tatoldata.instoreCount = 0;
              this.tatoldata.expectCount = this._thousandBitSeparator(this.productList.totalOutstoreCount);
              this.tatoldata.actualCount = this._thousandBitSeparator(this.productList.totalActualCount);
              this.productList.productDetailVos.push(this.tatoldata);
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     * 提交事件
     */
    submit() {
      this.$emit('success');
    },
    edit() {
      this.operateType = 2;
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    async save() {
      // 编辑保存 todo-wy
      if (this.operateType === 2) {
        console.log(this.productList.outstoreDate);
        if (this.productList.outstoreDate === null || !this.productList.outstoreDate) {
          errorTip('请填写出库日期');
        } else {
          // 保存
          let res = await httpPost('/malicrm/sm/outStoreRedeem/sureOutStore', this.productList);
          if (res.code === 200) {
            successTip('保存成功');
            this.$emit('cancel', true);
          } else {
            errorTip(res.message);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.viewform__one_body {
  display: flex;
  // flex: 1;
  width: 50%;
}
.viewform__main {
  display: flex;
  flex-direction: row;
}
.padding__top {
  padding-top: 20px;
}
.viewfrom_body_title {
  display: flex;
  width: 90px;
  color: rgba(115, 124, 140, 1);
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body_value {
  display: flex;
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 15px;
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body {
  width: 100%;
  min-height: 42px;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
}
.border_stock_add {
  width: 100%;
  min-height: 57px;
  border: 1px solid rgba(220, 223, 230, 1);
  margin: 17px 0;
  padding: 16px;
}
.erp-form__body {
  padding-bottom: 40px;
}
</style>
