import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '编号',
      prop: 'outstoreRedeemNo', // 属性
      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '冷库',
      prop: 'storageName',
      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '出库/赎货件数',
      prop: 'totalCount',
      minWidth: 163,
      value: [],
      type: 'number',
      filterProp: ['countMin', 'countMax'],
    },
    {
      label: '出库/赎货净重(kg)',
      prop: 'totalWeight',
      minWidth: 188,
      value: [],
      type: 'number',
      filterProp: ['weightMin', 'weightMax'],
    },
    {
      label: '出库实际货值',
      prop: 'totalActualAmount',
      propDesc: 'totalActualAmountDesc',
      minWidth: 188,
      value: [],
      type: 'number',
      filterProp: ['totalActualAmountMin', 'totalActualAmountMax'],
    },
    {
      label: '预约类型',
      prop: 'apptType',
      propDesc: 'apptTypeName',

      minWidth: 128,
      value: '',
      type: 'signSelect', // 筛选类型 multipleSelect 多选
      options: 'outstore_redeem_appt_type',
    },
    {
      label: '预约状态',
      prop: 'apptStatus', // actualWeightSum
      propDesc: 'apptStatusName',
      minWidth: 128,
      value: [],
      type: 'multiSelect',
      options: 'outstore_redeem_appt_status',
    },
    {
      label: '库存重量(KG)',
      prop: 'stockWeight', // actualWeightSum
      propDesc: 'stockWeightDesc',
      minWidth: 128,
      value: [],
      type: 'number',
      filterProp: ['stockWeightMin', 'stockWeightMax'],
    },
    {
      label: '库存状态',
      prop: 'stockStatus', // actualWeightSum
      propDesc: 'stockStatusDesc',
      minWidth: 128,
      value: '',
      type: 'signSelect',
      options: [
        {
          label: '已提空',
          value: 1,
        },
        {
          label: '未提空',
          value: 2,
        },
      ],
    },
    {
      label: '结算日期',
      prop: 'settleDate',
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['settleDateBegin', 'settleDateEnd'],
    },
    {
      label: '出库日期',
      prop: 'outstoreDate',

      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['outstoreDateBegin', 'outstoreDateEnd'],
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '实际出库件数',
      prop: 'totalActualCount',
      minWidth: 157,
      value: [],
      type: 'number',
      filterProp: ['actualCountMin', 'actualCountMax'],
    },
    {
      label: '预约时间',
      prop: 'createdTime',
      minWidth: 151,
      value: [],
      type: 'time',
      filterProp: ['createdTimeBegin', 'createdTimeEnd'],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 105,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button
        type="warning" plain
        v-if="hasPermission('erp:smOutStoreRedeem:sureRedeem') && row.apptType == 1 && row.apptStatus == 1"
        @click='confirmOut(row)'>确认出库</el-button>
      <el-button
       type="warning" plain
        v-if="hasPermission('erp:smOutStoreRedeem:sureRedeem') && row.apptType == 1 && row.apptStatus == 4"
        @click="confirmApply(row, '出库')">确认申请</el-button>
      <el-button
        type="warning" plain
        v-if="hasPermission('erp:smOutStoreRedeem:sureRedeem') && row.apptType == 2 && row.apptStatus == 1"
        @click='confirmRedemption(row)'>确认赎货</el-button>
      <el-button
        type="warning" plain
        v-if="hasPermission('erp:smOutStoreRedeem:sureRedeem') && row.apptType == 2 && row.apptStatus == 4"
        @click="confirmApply(row, '赎货')">确认申请</el-button>

        <el-button
        type="primary" plain
        v-if="hasPermission('erp:smOutStoreRedeem:sureRedeem') && row.apptType == 1 && row.apptStatus == 2"
        @click="updateAttach(row, '更新附件')">更新附件</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const confirmOut = (row) => {
            Instance.setupState.confirmOut(row);
          };
          const confirmApply = (row, name) => {
            Instance.setupState.confirmApply(row, name);
          };
          const confirmRedemption = (row) => {
            Instance.setupState.confirmRedemption(row);
          };
          const updateAttach = (row, name) => {
            Instance.setupState.updateAttach(row, name);
          };

          return { confirmOut, confirmApply, confirmRedemption, updateAttach };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
