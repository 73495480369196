<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看赎货</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <el-row :gutter="30" type="flex" class="flex-warp op-16">
        <el-col :span="12">
          <erp-view label="结算日期" :value="formData.settleDate"></erp-view>
        </el-col>
      </el-row>
      <el-col :span="24" style="margin-top: 30px">
        <el-table
          :data="formData.productDetailVos"
          border
          class="egrid erp-table__list erp-view__table"
        >
          <el-table-column
            label="销售合同号"
            prop="businessContractNo"
            class-name="no-pd"
            align="center"
            show-overflow-tooltip
            width="176"
          >
            <template #default="scope">
              <div>
                {{ filterName(scope.row.businessContractNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="国家"
            prop="countryName"
            align="center"
            class-name="no-pd"
            show-overflow-tooltip
            width="100"
          >
            <template #default="scope">
              <div>{{ filterName(scope.row.countryName) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="厂号"
            prop="plantNo"
            class-name="no-pd"
            align="center"
            show-overflow-tooltip
            width="100"
          >
            <template #default="scope">
              <div>
                {{ filterName(scope.row.plantNo) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="中文品名"
            class-name="no-pd"
            align="center"
            show-overflow-tooltip
          >
            <template #default="scope">
              <div>
                {{ filterName(scope.row.nameCn) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="赎货件数"
            class-name="no-pd"
            align="center"
            show-overflow-tooltip
            width="100"
          >
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.expectCount)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="赎货净重(kg)"
            class-name="no-pd"
            align="center"
            show-overflow-tooltip
            width="120"
          >
            <template #default="scope">
              <div>
                {{
                  filterName(
                    _thousandBitSeparator(
                      _formatFloat(scope.row.redeemWeight, 3)
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      writtenOffList: [
        {
          value: 0,
          label: '直送',
        },
        {
          value: 1,
          label: '入库',
        },
      ],
      formData: {
        // 绑定表单数据
        autoCertNo: 'dshbjsbd',
        hsCode: null,
        id: null,
        isWrittenOff: 1,
        orderId: null,
        unitPrice: null,
        validDate: null,
        version: null,
        weight: null,
      },
      formAttachmentList: [], // 上传保存用上传列表
      productList: [],
      objdata: {
        actualAmount: '',
        actualAmountDisplay: null,
        actualWeight: 0,
        actualWeightDisplay: null,
        category: null,
        contractAmount: 0,
        contractAmountDisplay: null,
        contractWeight: 0,
        contractWeightDisplay: null,
        country: null,
        elecInfoStatus: null,
        elecInfoStatusDisplay: null,
        healthCertNo: null,
        labelId: 0,
        nameCn: null,
        nameEn: null,
        packageCount: 0,
        packageCountDisplay: null,
        plantNo: null,
        tradeCurrency: null,
        tradeCurrencyDisplay: null,
        unitPrice: 0,
        unitPriceDisplay: null,
        isshow: true,
        varietyName: null,
      },
      Totaldata: {
        businessContractNo: '合计',
        country: null,
        countryName: null,
        expectCount: null,
        id: null,
        instoreCount: null,
        nameCn: null,
        plantNo: null,
        redeemValue: null,
        redeemWeight: null,
        sellStmtId: null,
        stockProductId: null,
        unitPrice: null,
        unitWeight: null,
      },
      rules: {},
    };
  },

  created() {
    this.getinit();
  },
  methods: {
    getinit() {
      this.ajax({
        type: 'GET',
        url: 'malicrm/sm/outStoreRedeem/redeemDetail/' + this.editData.id,
        data: null,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            if (this.formData.productDetailVos.length > 1) {
              this.formData.productDetailVos = this.formData.productDetailVos.filter(
                (item) => {
                  return item.expectCount;
                }
              );
              // this.Totaldata.instoreCount = this.formData.totalInstoreCount;
              this.Totaldata.redeemWeight = this.formData.totalWeight;
              this.Totaldata.expectCount = this.formData.totalRedeemCount;
              this.Totaldata.redeemValue = this.formData.totalValue;
              this.formData.productDetailVos.push(this.Totaldata);
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
</style>
