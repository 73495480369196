<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">更新附件</div>
      <div class="erp-form-bar">
        <el-button class="erp-primary__btn" size="small" type="primary" @click="submit">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <div class="op-16">
        <el-col :span="24">
          <div class="confirmadd__title no-leftborder" style="margin-top: 20px; margin-bottom: 0">出库单文件</div>
        </el-col>
        <el-col :span="24">
          <div class="border_stock_add" style="border: 1px solid rgba(220, 223, 230, 1); padding: 16px; margin: 17px 0">
            <SingleFileContent
              :fileType="1"
              :fromType="1"
              :tips="fileTips"
              @success="getOutBoundUrls"
              :max="3"
              :fileList="outBoundFileUrlsArray"
            ></SingleFileContent>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="confirmadd__title no-leftborder" style="margin-top: 30px; margin-bottom: 0">码单文件</div>
        </el-col>
        <el-col :span="24">
          <div class="border_stock_add" style="border: 1px solid rgba(220, 223, 230, 1); padding: 16px; margin: 17px 0">
            <SingleFileContent
              :fileType="1"
              :fromType="1"
              :tips="fileTips"
              @success="getCodeSheettUrls"
              :queryNum="0"
              :max="1"
              :fileList="codeSheetFileUrlsArray"
            >
            </SingleFileContent>
          </div>
        </el-col>
      </div>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: {
    SingleFileContent,
  },
  props: {
    editData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      attachmentDto: [], // 码单文件
      outBoundFileUrlsArray: [], // 出库单文件
      codeSheetFileUrlsArray: [], // 码单文件
      formAttachmentList: [],
      initData: {},
      formData: {
        attachmentDto: {}, //  码单文件
        attachmentDtos: [], // 出库单文件
        id: null,
      },
      isNewOrder: null,
      businessContractNo: null,
      customerContractNo: null,
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/outStoreDetail/' + this.editData.id,
        success: (res) => {
          if (res.code === 200) {
            if (res.result.attachment) {
              const data = {
                name: res.result.attachment.name,
                url: res.result.attachment.url,
              };
              this.codeSheetFileUrlsArray = [];
              this.codeSheetFileUrlsArray.push(data);
              this.formData.attachmentDto = data;
            }
            if (res.result.attachmentVos && res.result.attachmentVos.length > 0) {
              this.outBoundFileUrlsArray = [];
              for (let i = 0; i < res.result.attachmentVos.length; i++) {
                const data2 = {
                  name: res.result.attachmentVos[i].name,
                  url: res.result.attachmentVos[i].url,
                };
                this.outBoundFileUrlsArray.push(data2);
              }
              this.formData.attachmentDtos = this.outBoundFileUrlsArray;
            }
            this.isNewOrder = res.result.isNewOrder;
            this.businessContractNo = res.result.businessContractNo;
            this.customerContractNo = res.result.customerContractNo;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /*
      提交事件
     */
    submit() {
      // 如果一个文件都没有，则不让提示
      this.formData.id = this.editData.id;
      const valid = this.validForm();
      if (valid) {
        if (!this.formData.attachmentDto.url) {
          this.formData.attachmentDto = null;
        }
        if (this.formData.attachmentDtos.length === 0) {
          this.formData.attachmentDtos = null;
        }
        this.saveSubmitForm(this.formData);
      }
    },
    validForm() {
      let result = true;
      if (!this.formData.attachmentDto.url && this.formData.attachmentDtos.length === 0) {
        this.errorTip('请先上传文件');
        result = false;
      }
      return result;
    },
    /*
      返回操作
     */
    back() {
      this.$emit('cancel', false);
    },
    /**
     * 出库单文件
     */
    getOutBoundUrls(data) {
      let attachmentList = [];
      data.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      // 修改文件名
      attachmentList = this.formatFileName(attachmentList, '出库单');
      this.outBoundFileUrlsArray = attachmentList;
      this.initData.attachmentDtos = attachmentList;
      this.formData.attachmentDtos = attachmentList;
    },
    /**
     * 码单文件
     */
    getCodeSheettUrls(data) {
      // 获取到上传的文件码单文件
      let attachmentList = {};
      data.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList = data;
      });
      // 修改文件名
      if (attachmentList && attachmentList.name) {
        attachmentList = this.formatFileName(attachmentList, '码单');
        this.codeSheetFileUrlsArray = [attachmentList];
      } else {
        this.codeSheetFileUrlsArray = [];
      }
      this.initData.attachmentDto = attachmentList && attachmentList.name ? attachmentList : null;
      this.formData.attachmentDto = attachmentList;
    },
    /**
     * 修改文件名
     * @param fileArr 上传组件返回的文件数组[{name, url}]
     * @param type 文件类型: 出库单, 码单
     */
    formatFileName(fileArr, type) {
      const data = JSON.parse(JSON.stringify(fileArr));
      // 1.如果是旧的订单，则#销售合同号尾号(例如721-3)#-#证件类型#
      // 2.如果是新的订单，则#销售合同号客户编号(例如6003-0003)#-#合同编号#-#证件类型#
      // 销售合同号尾号 businessContractNo
      // 销售合同号客户编号 customerContractNo
      let { isNewOrder, businessContractNo, customerContractNo } = this;
      let index;
      if (isNewOrder) {
        // 新订单
        index = this.findChart(customerContractNo, '-', 1);
        businessContractNo = customerContractNo.substring(index + 1);
      } else {
        // 旧订单
        index = this.findChart(businessContractNo, '-', 1);
        businessContractNo = businessContractNo.substring(index + 1);
      }
      if (index > -1) {
        let name = businessContractNo;
        name += '-' + type;
        if (type === '出库单') {
          data.forEach((v, i) => {
            // 获取文件后缀名
            const suffix = v.url.substring(v.url.lastIndexOf('.') + 1);
            v.name = name + 0 + (i + 1) + '.' + suffix;
          });
          return data;
        }
        if (type === '码单') {
          // 获取文件后缀名
          const suffix = data.url.substring(data.url.lastIndexOf('.') + 1);
          data.name = name + '.' + suffix;
          return data;
        }
      } else {
        // 如果销售合同号不符合规则就直接返回
        return data;
      }
    },
    /**
     * 获取用-分隔的String的-内容
     * @param str 内容
     * @param cha 分隔符
     * @param num 第几个分隔符
     */
    findChart(str, cha) {
      const strIndexArr = [];
      for (let i = 0; i < str.length; i++) {
        if (cha === str[i]) {
          strIndexArr.push(i);
        }
      }
      const len = strIndexArr.length;
      if (len >= 2) {
        return strIndexArr[len - 2];
      } else {
        return -1;
      }
    },
    saveSubmitForm(value) {
      // 保存或编辑表单
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/outStoreRedeem/updateOutstoreAttachment', // to-do
        data: value,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功', 3000);
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.border_stock_add {
  margin-top: 10px;
  width: 100%;
  min-height: 57px;
}
</style>
